<template>
  <div class="check-in-dialog">
    <w-dialog
      ref="dialogRef"
      title="入住延期天数配置"
      width="20%"
      top="25vh"
      @wConfirm="handleSure"
    >
      <el-form
        ref="formRef"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              prop="ext_day"
              :label="'入住延期天数(0-' + maxDay + ')'"
            >
              <el-input-number
                v-model="formData.ext_day"
                :min="0"
                :max="maxDay"
                label="入住延期天数"
              >
              </el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { HotelApi } from "@/plugins/api.js";

export default {
  emits: ["reloadTable"],
  setup(props, { emit }) {
    const formData = ref({
      ext_day: 0,
    });

    const dialogRef = ref(null);
    const formRules = {
      ext_day: [
        {
          required: true,
          message: "请输入入住延期天数",
          trigger: "blur",
        },
      ],
    };
    const maxDay = ref(0);
    const hotelId = ref(null);
    function openDialog(data) {
      formData.value.ext_day = data.h_ext_day;
      hotelId.value = data.h_id;
      HotelApi.getPostpone().then((res) => {
        if (res.Code == 200) {
          maxDay.value = +res.Data.max_day;
          dialogRef.value.show();
        } else {
          let msg = res.Message ? res.Message : `获取酒店最大延期天数失败！`;
          ElMessage.error(msg);
        }
      });
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    const formRef = ref(null);
    function handleSure() {
      if (formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            dialogRef.value.isLoading = true;
            HotelApi.setPostpone({
              h_id: hotelId.value,
              ...formData.value,
            }).then((res) => {
              closeDialogLoading();
              if (res.Code === 200) {
                ElMessage.success(`操作成功！`);
                closeDialog();
                emit("reloadTable", true);
              } else {
                let msg = res.Message ? res.Message : `操作失败！`;
                ElMessage.error(msg);
              }
            });
          }
        });
      }
    }
    return {
      formData,
      formRules,
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      maxDay,
    };
  },
};
</script>

<style lang="scss">
.check-in-dialog {
  .el-input-number {
    width: 100%;
  }
}
</style>
