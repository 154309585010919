<template>
  <div class="config main-cnt">
    <div class="title">酒店列表</div>
    <div class="content">
      <common-table
        ref="hotelTable"
        tableHeight="calc(100vh - 275px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="HotelApi.getHotelList"
        :columns="tableColumns"
        @statusChange="statusChange"
        @edit="hotelEdit"
        @check="openCheckDialog"
        @showAlbum="showAlbum"
      >
        <template #more="{ row }">
          <el-dropdown>
            <el-button>
              更多{{ row.id }} <el-icon><CaretBottom /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="openNetDialog(row)"
                  v-if="
                    authData.indexOf('h_8ADnNj1kT5YPFS2LVrM0B4lbxEQd') != -1
                  "
                  >入账配置</el-dropdown-item
                >
                <el-dropdown-item
                  @click="openTimeDialog(row)"
                  v-if="
                    authData.indexOf('h_WjDUMuoHgtEpV4s54trIt7afs7Ho') != -1
                  "
                  >入住延期</el-dropdown-item
                >
              </el-dropdown-menu></template
            >
          </el-dropdown>
        </template>
      </common-table>
    </div>

    <!-- 酒店图册 弹框 -->
    <w-dialog
      ref="albumDialog"
      class="album-dialog"
      title="酒店图册"
      width="55%"
      btnWidth="140px"
      top="8vh"
      v-loading.fullscreen.lock="fullLoading"
      @wConfirm="albumDialog.close()"
      :hideFooter="true"
    >
      <div v-for="(item, index) in albumList" :key="index">
        <h3>{{ item.sft_name }}</h3>
        <template v-if="!item.child">
          <div class="img-wp">
            <img-upload
              :text="item.sft_name.includes('视频') ? '上传视频' : '上传图片'"
              :type="item.sft_name.includes('视频') ? 'video' : 'image'"
              :fileList="item.files"
              uploadTitle="图片"
              :limit="5"
              @uploadFile="uploadFile($event, item)"
              @deleteFile="deleteFile"
              :suggestText="item.sft_pic_size_text"
            ></img-upload>
          </div>
          <div class="img-wp" v-if="item.is_cover == 2">
            <img-upload
              text="上传封面"
              :fileList="item.cover_files"
              :limit="1"
              @uploadFile="uploadFile($event, item, true)"
              @deleteFile="deleteFile"
              :suggestText="item.sft_cover_pic_size_text"
            ></img-upload>
          </div>
        </template>
        <div class="child-wp" v-for="(itm, i) in item.child" :key="i">
          <div class="img-wp">
            <img-upload
              :text="itm.sft_name"
              :fileList="itm.files"
              uploadTitle="图片"
              :limit="5"
              @uploadFile="uploadFile($event, itm)"
              @deleteFile="deleteFile"
              :suggestText="item.sft_pic_size_text"
            ></img-upload>
          </div>
          <div class="img-wp">
            <img-upload
              text="上传封面"
              :fileList="itm.cover_files"
              :limit="1"
              @uploadFile="uploadFile($event, itm, true)"
              @deleteFile="deleteFile"
              :suggestText="item.sft_cover_pic_size_text"
            ></img-upload>
          </div>
        </div>
      </div>
    </w-dialog>

    <!-- 编辑酒店弹框 -->
    <w-dialog
      ref="editDialog"
      class="edit-dialog"
      title="编辑酒店信息"
      width="55%"
      btnWidth="140px"
      top="10vh"
      @wConfirm="editConfirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="所属项目" prop="village">
          <el-input
            v-model="ruleForm.village"
            disabled
            placeholder="请选择所属项目"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属酒店" prop="hotel">
          <el-input
            v-model="ruleForm.hotel"
            disabled
            placeholder="请选择所属酒店"
          ></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="联系电话" prop="tel">
          <el-input
            v-model="ruleForm.tel"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="酒店地址" prop="addr">
          <el-input
            v-model="ruleForm.addr"
            placeholder="请输入酒店地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="周边地标" prop="landmark">
          <el-input
            v-model="ruleForm.landmark"
            placeholder="请输入周边地标"
          ></el-input>
        </el-form-item>
        <el-form-item label="特色标签1" prop="tag1">
          <el-input
            v-model="ruleForm.tag1"
            placeholder="请输入标签名"
          ></el-input>
        </el-form-item>
        <el-form-item label="特色标签2" prop="tag2">
          <el-input
            v-model="ruleForm.tag2"
            placeholder="请输入标签名"
          ></el-input>
        </el-form-item>
        <el-form-item label="特色标签3" prop="tag3">
          <el-input
            v-model="ruleForm.tag3"
            placeholder="请输入标签名"
          ></el-input>
        </el-form-item>
        <el-form-item label="特色标签4" prop="tag4">
          <el-input
            v-model="ruleForm.tag4"
            placeholder="请输入标签名"
          ></el-input>
        </el-form-item>
        <el-form-item class="intro" label="酒店简介" prop="intro">
          <el-input
            v-model="ruleForm.intro"
            :rows="7"
            resize="none"
            type="textarea"
            placeholder="请输入酒店简介"
          ></el-input>
        </el-form-item>
        <el-form-item class="intro" label="入住政策" prop="policy">
          <div ref="policyEditorElem"></div>
        </el-form-item>
        <el-form-item class="intro" label="预定须知" prop="notice">
          <div ref="noticeEditorElem"></div>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item class="intro" label="选择上线小程序" prop="selects">
          <el-checkbox-group v-model="ruleForm.selects">
            <el-checkbox
              :label="item.mp_id"
              v-for="item in miniAppList"
              :key="item.mp_id"
              >{{ item.mp_name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </w-dialog>
    <CheckDialog ref="checkDialog"></CheckDialog>
    <NetConfig ref="netConfig" @submit="handleNetConfig"></NetConfig>
    <CheckTimeDialog
      ref="checkTimeDialog"
      @reloadTable="reloadTable"
    ></CheckTimeDialog>
  </div>
</template>
<script setup>
import {
  ref,
  reactive,
  nextTick,
  onMounted,
  toRaw,
  computed,
  watch,
} from "vue";
// 导入图片上传组件
import imgUpload from "@/components/img-upload/img-upload.vue";
// 引入富文本编辑器
import Editor from "wangeditor";
import { ElMessage } from "element-plus";
import { BasicApi, HotelApi } from "@/plugins/api.js";
import { useStore } from "vuex";
import CheckDialog from "../components/CheckDialog.vue";
import CheckTimeDialog from "../components/CheckTimeDialog.vue";
import { CaretBottom } from "@element-plus/icons-vue";
import NetConfig from "@/views/scenic/components/NetConfig.vue";
import * as Qiniu from "qiniu-js";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const qiniuData = computed(() => store.state.Config.qiniuData);

const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 酒店表格对象 */
const hotelTable = ref(null);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "h_id",
    label: "酒店ID",
    minWidth: 50,
    color: "--text-color",
  },
  {
    prop: "project",
    prop2: "p_name",
    label: "所属项目",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "h_name",
    label: "酒店名称",
    minWidth: 140,
    color: "--text-color",
  },
  {
    prop: "h_hotline",
    label: "联系电话",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "h_address",
    label: "地址信息",
    minWidth: 160,
    color: "--text-color",
    showTooltip: true,
  },
  {
    prop: "h_introduce",
    label: "酒店简介",
    minWidth: 160,
    color: "--text-third-color",
    showTooltip: true,
  },
  {
    type: "switch",
    prop: "h_status",
    label: "酒店状态",
    minWidth: 120,
    token: "h_7Mr66pkNUUQknciOCBhbecSI2222",
  },
  {
    type: "block",
    prop: "file_number",
    label: "图册",
    minWidth: 80,
    token: "h_7Mr66pkNUUQknciOCBhbecSI3333",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 300,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "h_7Mr66pkNUUQknciOCBhbecSI1111",
        className: "theme-font-btn",
      },
      {
        name: "退款管理员",
        action: "check",
        token: "h_7Mr66pkNUUQknciOCBhbecSIiFDc",
        className: "black-font-btn",
      },
      {
        type: "customRender",
        name: "更多",
        action: "more",
        token: "h_7Mr66pkNUUQknciOCBhbecSIiFDc",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 当前 行数据 */
const currentRow = ref(null);
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    h_id: row.h_id,
    h_status: row.h_status == 1 ? 2 : 1,
  };
  HotelApi.setHotelStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("酒店状态修改成功！");
      // 重新获取园区列表数据
      reloadTable();
    } else {
      let msg = res.Message ? res.Message : "酒店状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
/** 表格编辑 */
const hotelEdit = (row) => {
  currentRow.value = row;
  ruleForm.village =
    row.project && row.project.p_name ? row.project.p_name : "";
  ruleForm.hotel = row.h_name;
  ruleForm.tel = row.h_hotline;
  ruleForm.addr = row.h_address;
  ruleForm.landmark = row.h_coordinate;
  ruleForm.tag1 = row.h_tag && row.h_tag[0] ? row.h_tag[0] : "";
  ruleForm.tag2 = row.h_tag && row.h_tag[1] ? row.h_tag[1] : "";
  ruleForm.tag3 = row.h_tag && row.h_tag[2] ? row.h_tag[2] : "";
  ruleForm.tag4 = row.h_tag && row.h_tag[3] ? row.h_tag[3] : "";
  ruleForm.intro = row.h_introduce;
  ruleForm.images = row.images || [];
  isEdit.value = true;
  editDialog.value.show();
  nextTick(() => {
    store.dispatch("getQiniuData");
    policyEditorInit();
    noticeEditorInit();
  });
  getMiniApps();
};
/** 查看图册 */
const showAlbum = (row) => {
  currentRow.value = row;
  albumDialog.value.show();
  store.dispatch("getQiniuData");
  getAlbumDatas({ h_id: row.h_id });
};

/** 图册弹框 */
const albumDialog = ref(null);
/** 图册列表数据 */
const albumList = ref([]);
/** 是否正在上传图片 */
const fullLoading = ref(false);
/** 多文件上传列表 */
const mulFileObj = ref({
  h_id: "",
  hrt_id: "",
  sft_id: "",
  file_type: "",
  files: [],
  cover_files: [],
});
/** 获取图册数据 */
const getAlbumDatas = (data) => {
  HotelApi.getHotelPhoto(data).then((res) => {
    if (res.Code === 200) {
      albumList.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "数据获取失败！";
      ElMessage.error(msg);
    }
  });
};
/** 文件上传 */
const uploadFile = (data, obj, isCover) => {
  if (authData.value.indexOf("h_7Mr66pkNUUQknciOCBhbecSI4444") != -1) {
    fullLoading.value = true;
    mulFileObj.value.h_id = currentRow.value.h_id;
    mulFileObj.value.hrt_id = obj.sf_hrt_id ? obj.sf_hrt_id : "";
    mulFileObj.value.sft_id = obj.sft_id;
    mulFileObj.value.file_type = obj.sft_name.includes("视频") ? 2 : 1;
    if (isCover) {
      mulFileObj.value.cover_files.push(data.key);
    } else {
      mulFileObj.value.files.push(data.key);
    }
    // 去抖上传
    debounce(uploadSubmit, 300);
  } else {
    ElMessage.warning("您没有操作的权限！");
  }
};
/** 定时器 id */
const timer = ref(null);
/** 去抖函数 */
const debounce = (fn, delay) => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = setTimeout(fn, delay);
};
/** 上传提交 */
const uploadSubmit = () => {
  HotelApi.addHotelPhoto(mulFileObj.value).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("上传成功！");
      // 获取一次图册数据
      getAlbumDatas({ h_id: currentRow.value.h_id });
    } else {
      let msg = res.Message ? res.Message : "上传失败！";
      ElMessage.error(msg);
    }
    fullLoading.value = false;
  });
  mulFileObj.value = {
    h_id: "",
    hrt_id: "",
    sft_id: "",
    file_type: "",
    files: [],
    cover_files: [],
  };
};
/** 文件删除 */
const deleteFile = (data) => {
  if (authData.value.indexOf("h_7Mr66pkNUUQknciOCBhbecSI5555") != -1) {
    HotelApi.delHotelPhoto({ sf_id: data.sf_id }).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("删除成功！");
        // 获取一次图册数据
        getAlbumDatas({ h_id: currentRow.value.h_id });
      } else {
        let msg = res.Message ? res.Message : "删除失败！";
        ElMessage.error(msg);
      }
    });
  } else {
    ElMessage.warning("您没有操作的权限！");
  }
};

/** 编辑弹框 */
const editDialog = ref(null);
/** 当前是否是编辑操作 */
const isEdit = ref(false);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  village: "", // 项目
  hotel: "", // 酒店
  tel: "", // 联系电话
  addr: "", // 酒店地址
  landmark: "", // 周边地标
  tag1: "", // 标签1
  tag2: "", // 标签2
  tag3: "", // 标签3
  tag4: "", // 标签4
  intro: "", // 酒店简介
  policy: "", // 入住政策
  notice: "", // 预定须知
  selects: [], // 上线小程序
});
/** 表单规则对象 */
const rules = reactive({
  village: [
    {
      required: false,
      message: "请选择所属项目",
      trigger: "change",
    },
  ],
  hotel: [
    {
      required: false,
      message: "请选择所属酒店",
      trigger: "change",
    },
  ],
  tel: [
    {
      required: true,
      message: "请输入联系电话",
      trigger: "blur",
    },
  ],
  addr: [
    {
      required: true,
      message: "请输入酒店地址",
      trigger: "blur",
    },
  ],
  landmark: [
    {
      required: true,
      message: "请输入周边地标",
      trigger: "blur",
    },
  ],
  intro: [
    {
      required: true,
      message: "请输入酒店简介",
      trigger: "blur",
    },
  ],
  policy: [
    {
      required: true,
      message: "请输入入住政策",
      trigger: "blur",
    },
  ],
  notice: [
    {
      required: true,
      message: "请输入预定须知",
      trigger: "blur",
    },
  ],
  selects: [
    {
      required: false,
      message: "请选择上线小程序",
      trigger: "change",
    },
  ],
});

/** 入住政策 富文本容器 */
const policyEditorElem = ref(null);
/** 入住政策 富文本实例对象 */
const policyEditor = ref(null);
// 入住政策 富文本初始化
const policyEditorInit = () => {
  policyEditor.value = new Editor(policyEditorElem.value);
  policyEditor.value.config.colors = [
    "#000000",
    "#eeece0",
    "#1c487f",
    "#4d80bf",
    "#0072f6",
    "#1a1a1a",
    "#FFB900",
    "#EB5774",
    "#f2f2f2",
    "#666666",
    "#999999",
    "#cccccc",
  ];
  // 配置 onchange 回调函数
  policyEditor.value.config.onchange = (newHtml) => {
    ruleForm.policy = newHtml;
    addForm.value.validateField("policy");
  };
  // 自定义菜单配置
  policyEditor.value.config.menus = [
    "head",
    "bold",
    "fontSize",
    "fontName",
    "italic",
    "underline",
    "strikeThrough",
    "indent",
    "lineHeight",
    "foreColor",
    "justify",
    "quote",
    "splitLine",
    "image",
    "undo",
    "redo",
  ];
  // 限制上传图片格式
  policyEditor.value.config.uploadImgAccept = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
  ];
  policyEditor.value.config.qiniu = true;
  policyEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
  const upToken = qiniuData.value.token;
  const domain = qiniuData.value.http_domain;
  // 创建富文本实例
  policyEditor.value.config.customUploadImg = (file) => {
    if (file.length) {
      file.forEach((el) => {
        const myDate = new Date();
        const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
        const timeData = Date.parse(new Date()); //当前时间时时间戳
        const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
        const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
        const filename = el.name;
        const index = filename.lastIndexOf(".");
        const suffix = filename.substr(index + 1);
        const key = `hotel-policy-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
        const config = {
          // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
          cdnUphost: domain,
        };
        const putExtra = {
          fname: "", //文件原文件名
          params: {}, //用来放置自定义变量
          mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
        };
        const observable = Qiniu.upload(el, key, upToken, putExtra, config);
        observable.subscribe({
          error: () => {},
          complete: (res) => {
            const sourceLink = domain + res.key;
            policyEditor.value.cmd.do(
              "insertHtml",
              '<img src="' + sourceLink + '" style="max-width:100%;"/>'
            );
            ruleForm.images.push({
              filename: filename,
              qiniu_key: res.key,
              size: el.size,
            });
          },
        });
      });
    }
  };

  policyEditor.value.config.height = 300;
  policyEditor.value.create();
  policyEditor.value.txt.html(currentRow.value.h_check_in_policy);
};

/** 预定须知 富文本容器 */
const noticeEditorElem = ref(null);
/** 预定须知 富文本实例对象 */
const noticeEditor = ref(null);
// 预定须知 富文本初始化
const noticeEditorInit = () => {
  noticeEditor.value = new Editor(noticeEditorElem.value);
  noticeEditor.value.config.colors = [
    "#000000",
    "#eeece0",
    "#1c487f",
    "#4d80bf",
    "#0072f6",
    "#1a1a1a",
    "#FFB900",
    "#EB5774",
    "#f2f2f2",
    "#666666",
    "#999999",
    "#cccccc",
  ];
  // 配置 onchange 回调函数
  noticeEditor.value.config.onchange = (newHtml) => {
    ruleForm.notice = newHtml;
    addForm.value.validateField("notice");
  };
  // 自定义菜单配置
  noticeEditor.value.config.menus = [
    "head",
    "bold",
    "fontSize",
    "fontName",
    "italic",
    "underline",
    "strikeThrough",
    "indent",
    "lineHeight",
    "foreColor",
    "justify",
    "quote",
    "splitLine",
    "image",
    "undo",
    "redo",
  ];
  // 限制上传图片格式
  noticeEditor.value.config.uploadImgAccept = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
  ];
  noticeEditor.value.config.qiniu = true;
  noticeEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
  const upToken = qiniuData.value.token;
  const domain = qiniuData.value.http_domain;
  noticeEditor.value.config.customUploadImg = (file) => {
    if (file.length) {
      file.forEach((el) => {
        const myDate = new Date();
        const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
        const timeData = Date.parse(new Date()); //当前时间时时间戳
        const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
        const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
        const filename = el.name;
        const index = filename.lastIndexOf(".");
        const suffix = filename.substr(index + 1);
        const key = `hotel-notice-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
        const config = {
          // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
          cdnUphost: domain,
        };
        const putExtra = {
          fname: "", //文件原文件名
          params: {}, //用来放置自定义变量
          mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
        };
        const observable = Qiniu.upload(el, key, upToken, putExtra, config);
        observable.subscribe({
          error: () => {},
          complete: (res) => {
            const sourceLink = domain + res.key;
            noticeEditor.value.cmd.do(
              "insertHtml",
              '<img src="' + sourceLink + '" style="max-width:100%;"/>'
            );
            ruleForm.images.push({
              filename: filename,
              qiniu_key: res.key,
              size: el.size,
            });
          },
        });
      });
    }
  };

  noticeEditor.value.config.height = 300;
  // 创建富文本实例
  noticeEditor.value.create();
  noticeEditor.value.txt.html(currentRow.value.h_reservation_notice);
};

/** 酒店编辑确认 */
const editConfirm = () => {
  addForm.value.validate((valid) => {
    if (valid) {
      editDialog.value.isLoading = true;
      let data = {
        h_id: currentRow.value.h_id,
        h_hotline: ruleForm.tel,
        h_address: ruleForm.addr,
        h_coordinate: ruleForm.landmark,
        h_introduce: ruleForm.intro,
        h_check_in_policy: ruleForm.policy,
        h_reservation_notice: ruleForm.notice,
        images: ruleForm.images,
        h_tag: [],
        mini_mp_id: toRaw(ruleForm.selects),
      };
      if (ruleForm.tag1) {
        data.h_tag.push(ruleForm.tag1);
      }
      if (ruleForm.tag2) {
        data.h_tag.push(ruleForm.tag2);
      }
      if (ruleForm.tag3) {
        data.h_tag.push(ruleForm.tag3);
      }
      if (ruleForm.tag4) {
        data.h_tag.push(ruleForm.tag4);
      }
      HotelApi.hotelEdit(data).then((res) => {
        editDialog.value.isLoading = false;
        if (res.Code === 200) {
          ElMessage.success("酒店编辑成功！");
          editDialog.value.close();
          // 获取一次表格数据
          reloadTable();
        } else {
          let msg = res.Message ? res.Message : "酒店编辑失败！";
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 上线小程序列表 */
const miniAppList = ref([]);
/** 获取上线小程序 */
const getMiniApps = () => {
  let data = {
    type: 1,
    id: currentRow.value.h_id,
  };
  BasicApi.getMiniSource(data).then((res) => {
    if (res.Code === 200) {
      ruleForm.selects = res.Data.checked;
      miniAppList.value = res.Data.mini_list;
    } else {
      let msg = res.Message ? res.Message : "小程序列表数据获取失败！";
      ElMessage.error(msg);
    }
  });
};
const checkDialog = ref(null);
function openCheckDialog(row) {
  checkDialog.value.openDialog("hotel", row.h_name, row.h_id, {
    delete: "h_XLD4JCcyw9FTmMznIN8Vpjlu1hxt",
    add: "h_LtwyREOdKfNchHQ3D87lrXmesCSA",
  });
}
const netConfig = ref(null);
function openNetDialog(data) {
  netConfig.value.openDialog("hotel", data);
}
function handleNetConfig(data) {
  HotelApi.setHotelAccount(data).then((res) => {
    netConfig.value.closeDialogLoading();
    if (res.Code === 200) {
      ElMessage.success(`操作成功！`);
      netConfig.value.closeDialog();
      // 重新获取门票列表数据
      reloadTable();
    } else {
      let msg = res.Message ? res.Message : `操作失败！`;
      ElMessage.error(msg);
    }
  });
}
const checkTimeDialog = ref(null);
function openTimeDialog(data) {
  checkTimeDialog.value.openDialog(data);
}
function reloadTable() {
  hotelTable.value.tableLoad();
}
onMounted(() => {
  reloadTable();
});
</script>

<style lang="scss">
.config {
  font-family: "Source Han Sans CN";
  .content {
    padding: 20px;
  }
  .img-tips {
    margin-left: 56px;
    line-height: 4px;
  }
  .el-dropdown {
    .el-button {
      margin-left: 10px;
      padding: 0;
      width: 76px;
      background-color: var(--text-white-color);
      color: red;
      height: 24px !important;
      min-height: 0;
      border-radius: 20px;
      background-color: var(--search-uncheck-bg-color);
      color: var(--text-color);
      border: 1px solid var(--search-uncheck-bg-color);
      &:hover {
        background-color: var(--text-color);
        color: var(--text-white-color);
      }
    }
  }
  .edit-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 0 15px 30px;
        .add-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 20px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-select {
                width: 100%;
                .el-input {
                  width: 100%;
                }
              }
            }
          }
          .el-form-item.intro {
            width: 100%;
          }
          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }
  .album-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 20px 20px 30px;
        h3 {
          font-size: 15px;
          color: var(--text-color);
          font-weight: 700;
          padding: 15px 0 10px;
        }
        h3:first-child {
          padding: 0 0 10px;
        }
        .img-wp {
          margin-bottom: 20px;
        }
        .child-wp {
          border-bottom: 1px dashed #ccc;
          margin-bottom: 20px;
        }
        .child-wp:last-child {
          border: none;
          margin: 0;
        }
      }
    }
  }
}
</style>
